import React from 'react';
import { graphql } from 'gatsby';
import uniq from 'lodash/uniq';

import {
  Container,
  Box,
  Flex,
  Text,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';

import SEO from 'timelesstime-ui/components/seo';
import BlockQuote from 'timelesstime-ui/components/block-quote';
import ContactForm from 'timelesstime-ui/components/contact-form';
import Breadcrumbs from 'timelesstime-ui/components/breadcrumbs';
import Html from 'timelesstime-ui/components/html';
import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import { Organization as OrganizationStructuredData } from 'timelesstime-ui/components/json-ld';
import Heading from 'timelesstime-ui/components/heading';
import PageLayout from '../components/layout';

const formatPhoneNumberAsLink = (phoneNum) => {
  const phoneNumberDigits = [...phoneNum.matchAll(/[0-9]/g)].map(
    ([digit]) => digit,
  );
  const coreDigitsInNumber = phoneNumberDigits.slice(
    phoneNumberDigits.length - 10,
  ); // remove the leading +44 and 0 if present
  // format correctly, e.g. +44 (0)1450 372274 => tel:+44-1450-372274
  return `tel:+44-${coreDigitsInNumber
    .slice(0, 4)
    .join('')}-${coreDigitsInNumber.slice(4).join('')}`;
};

const ContactDetails = ({ contactDetails, homePage }) => (
  <Flex flexDir="column">
    <Flex flexDir="column">
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        {contactDetails.nodes.map(
          ({
            id,
            companyName,
            address1,
            address2,
            address3,
            town,
            county,
            postCode,
            phoneNumber1,
            phoneNumber2,
          }) => (
            <Flex key={id} justifyContent="space-between" flexDir="column">
              <Box as="address">
                {companyName && (
                  <Text as="strong" color="tt.darkBlue" mb={2} display="block">
                    {companyName}
                  </Text>
                )}
                {address1 && <Text display="block">{address1}</Text>}
                {address2 && <Text display="block">{address2}</Text>}
                {address3 && <Text display="block">{address3}</Text>}
                {town && <Text display="block">{town}</Text>}
                {county && <Text display="block">{county}</Text>}
                {postCode && <Text display="block">{postCode}</Text>}
              </Box>
              <VStack mt={4} spacing={1} alignItems="flex-start">
                {uniq([phoneNumber1, phoneNumber2]).map((phoneNumber) => {
                  if (!phoneNumber) {
                    return null;
                  }
                  return (
                    <CanonicalLink
                      key={phoneNumber}
                      color="tt.darkBlue"
                      to={formatPhoneNumberAsLink(phoneNumber)}
                    >
                      {phoneNumber}
                    </CanonicalLink>
                  );
                })}
              </VStack>
            </Flex>
          ),
        )}
      </SimpleGrid>
    </Flex>
    {homePage?.quotes?.[1]?.text && (
      <BlockQuote
        mt={12}
        text={homePage?.quotes?.[1]?.text || ''}
        person={homePage?.quotes?.[1]?.pers || ''}
        position={homePage?.quotes?.[1]?.posi || ''}
        company={homePage?.quotes?.[1]?.comp || ''}
      />
    )}
  </Flex>
);

const ContactPage = ({ data: { homePage, contactPage, contactDetails } }) => (
  <PageLayout>
    <SEO
      title={contactPage.title}
      keywords={contactPage.fields.keywords}
      description={contactPage.metaDescription}
      canonicalPath="/contact/"
      thumbnail={contactPage.jsonLdThumbnailImage}
    />
    <OrganizationStructuredData />

    <Container maxW="container.lg" mt={2} mb={4}>
      <Breadcrumbs
        color="gray.500"
        crumbs={[
          {
            url: contactPage.fields.path,
            title: contactPage.title,
          },
        ]}
      />
    </Container>

    <Container maxW="container.lg" mt={8} mb={12}>
      <Heading as="h1">Contact TimelessTime</Heading>

      <Box as="section">
        <Html source={contactPage.fields.contentHtml} headerLevelStart={1} />
      </Box>

      <SimpleGrid as="section" columns={[1, 1, 2]} mt={12} mb={8} spacing={8}>
        <ContactForm />
        <ContactDetails homePage={homePage} contactDetails={contactDetails} />
      </SimpleGrid>
    </Container>
  </PageLayout>
);

export const query = graphql`
  query ContactPageQuery {
    contactPage: contentfulPage(slug: { eq: "contact" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
    contactDetails: allContentfulContactDetails(
      sort: { fields: [order], order: ASC }
      limit: 2
    ) {
      nodes {
        id
        companyName
        address1
        address2
        address3
        town
        county
        postCode
        phoneNumber1
        phoneNumber2
      }
    }
    homePage: contentfulHomePage(slug: { eq: "home" }) {
      quotes {
        id
        person
        position
        company
        # get raw text here since we need it for structured data
        text {
          internal {
            content
          }
        }
        fields {
          textHtml
        }
      }
    }
  }
`;

export default ContactPage;
